/**
 *
 * Session List Page
 * Created by Om Bhatia on 16/09/2022 14:10 GMT+0530 (India Standard Time)
 * Description - List page to show relates session
 *
 */
import { useEffect } from "react";
import SideImage from "../../components/SideImage/SideImage";
import ListDetail from "../../components/ListData/ListData";
import { useLoader } from "../../router/LoaderProvider";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
const ListPage = () => {
  const { setLoading } = useLoader();
  const { state } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!state) {
      navigate("/");
    }
  }, []);
  if (!state) {
    return <></>;
  }

  //Function to fetch specific user session data
  const showSessionDetails = async (requiredData,fullSessionData) => {
    try {
      setLoading(true);
      if (requiredData && fullSessionData) {
        setLoading(false);
        navigate("/details", { state: {requiredData,fullSessionData} });
      }
    } catch (err) {
      setLoading(false);
      toast.error(`${err.message}`, { autoClose: 10000 });
    }
  };
  return (
    <main className="flex w-screen h-screen flex-col bg-white overflow-hidden">
      {/* <Navbar /> */}
      <section className="h-screen w-full flex flex-col lg:flex-row lg:h-[100vh]">
        <aside className="h-1/3 w-full lg:block lg:w-1/2 lg:h-full">
          <SideImage />
        </aside>
        <aside className="w-full h-2/3 lg:w-1/2 lg:h-full flex flex-col lg:items-center lg:justify-center">
          <section className="w-full h-full flex flex-col p-4 lg:p-5 lg:w-[80%] lg:h-[95%] lg:py-5 overflow-auto overflow-x-hidden">
            <h3 className="text-2xl lg:text-3xl font-bold font-roboto my-5 lg:mt-5 lg:mb-6 ">
              Receipts List
            </h3>
            {state.map((data, index) => (
              <ListDetail
                key={index}
                showSessionDetails={showSessionDetails}
                data={data}
              />
            ))}
          </section>
        </aside>
      </section>
    </main>
  );
};
export default ListPage;
