/**
 *
 * Session Detail Page
 * Created by Om Bhatia on 16/09/2022 14:10 GMT+0530 (India Standard Time)
 * Description - Detail page to show session details
 *
 */
import { useState, useRef, useEffect } from "react";
import { jsPDF } from "jspdf";
import desktopBack from "../../shared/assets/icons/desktop-back.png";
import desktopDownoad from "../../shared/assets/icons/desktop-download.png";
import mobileBack from "../../shared/assets/icons/mobile-back-grey.png";
import mobileDownload from "../../shared/assets/icons/mobile-download.png";
import emailDesktop from "../../shared/assets/icons/email-desktop.png";
import emailMobile from "../../shared/assets/icons/email-mobile.png";
import mfg from "../../shared/assets/icons/mfg-detail.png";
import close from "../..//shared/assets/icons/close.png";
import stationFinder from "../../shared/assets/images/station-finder.gif";
import paymentHistory from "../../shared/assets/images/payment-history.gif";
import { sendUserSessionEmail } from "../../api/service";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useLoader } from "../../router/LoaderProvider";
import {SESSION_ID, START_TIME, TRANSACTION_DATE_AND_TIME, TRANSACTION_ID, VALETING } from "../../shared/constants/appConstant";
const Details = () => {
  const navigate = useNavigate();
  const pdfRef = useRef(null);
  const [showPopup, setPopup] = useState(false);
  const [sendEmailModal, setSendEmailModal] = useState(false);
  const { state } = useLocation();
  const{requiredData,fullSessionData}=state||{};
  const { setLoading } = useLoader();
  useEffect(() => {
    if (!state) {
      navigate("/");
    }
  }, []);
  if (!state) {
    return <></>;
  }

  //Function to set mobile popup
  const setPopupStatus = () => {
    setPopup((prevState) => !prevState);
  };

  //Function for downloading receipt
  const downloadReceiptHandler = (email) => {
    const doc = new jsPDF("p", "pt", "a4");
    let pageWidth =
      doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    const content = pdfRef.current;
    let receiptNode = content.cloneNode(true);
    receiptNode.style.minWidth = "450px";
    receiptNode.style.maxWidth = "450px";
    // doc.setFont('Roboto-Regular');
    doc.html(receiptNode, {
      callback: async function (pdf) {
        if (email) {
          try {
            const dataUri = pdf.output("datauristring");

            // Extract the base64 part from the data URI
            const base64Data = dataUri.split(',')[1];
            
            // var blob = pdf.output("blob");
            var bodyFormData = new FormData();
            bodyFormData.append("email", email);
            bodyFormData.append(
              "pdf_file_name",
              `mfg-contactless-receipt-${requiredData.dateForFileName}`
              );
              bodyFormData.append("session_data", JSON.stringify(fullSessionData));
              setSendEmailModal(false);
              bodyFormData.append("pdf_file", base64Data);
            const response = await sendUserSessionEmail(bodyFormData);
            if (response.data) {
              if (!response.data.status) throw new Error(response.data.message);
              setLoading(false);
              toast.success(`${response.data.message}`, { autoClose: 10000 });
            } else {
              throw new Error(response.message);
            }
          } catch (err) {
            setLoading(false);
            toast.error(`${err.message}`, { autoClose: 10000 });
          }
        } else {
          pdf.save(
            `mfg-contactless-receipt-${requiredData.dateForFileName}.pdf`
          );
        }
      },
      margin: [10, 72.5, 10, 72.5],
      windowWidth: pageWidth,
    });
  };
  const downloadMobileReceipt = () => {
    downloadReceiptHandler(null);
    setPopupStatus();
  };
  const downloadDesktopReceipt = () => {
    downloadReceiptHandler(null);
  };
  const downloadApp = () => {
    if (window.navigator.userAgent.indexOf("Android") !== -1) {
      window.open(
        "https://play.google.com/store/apps/details?id=com.mfg.evconnect",
        "_blank"
      );
    } else {
      window.open(
        "https://apps.apple.com/gb/app/mfg-connect/id1579481772",
        "_blank"
      );
    }
  };
  const shareReceipt = () => {
    setSendEmailModal(true);
  };

  // Functions for handling sharing email method
  const shareReceiptHandler = (event) => {
    event.preventDefault();
    setLoading(true);
    downloadReceiptHandler(event.target.email.value);
  };
  const MobilePopup = () => {
    return (
      <div className="w-screen he-screen flex items-center justify-center absolute z-10 top-0 bottom-0 right-0 left-0 bg-black bg-opacity-40">
        <div className="w-[90%] h-auto bg-white flex flex-col items-center px-3 py-3 shadow-xl">
          <div className="self-end mb-5" onClick={setPopupStatus}>
            <img src={close} alt="close" />
          </div>
          <div className="flex items-center justify-around mb-5">
            <div className="flex flex-col items-center w-[30%] h-full">
              <div className="w-[60%] flex items-center mb-2 ">
                <img src={stationFinder} alt="Station finder" />
              </div>
              <h6 className="font-roboto font-semibold text-xs text-grey-dark text-center">
                Station Finder
              </h6>
              <p className="text-center text-xs font-roboto text-grey">
                Easy to find nearby charging stations
              </p>
            </div>
            {/* <hr className="border-grey-dark border-10 w-5 self-baseline" /> */}
            {/* <div className="flex flex-col items-center w-[30%] h-full">
              <div className="w-[60%] flex items-center mb-2">
                <img src={tripPlanner} alt="Trip planner" />
              </div>
              <h6 className="font-roboto font-semibold text-xs text-grey-dark text-center">
                Trip Planner
              </h6>
              <p className=" text-center text-xs font-roboto text-grey">
                Plan your trip and charge accordingly
              </p>
            </div> */}
            {/* <hr className="border-grey-dark border-10 w-5 self-baseline" /> */}
            <div className="flex flex-col items-center w-[30%] h-full">
              <div className="w-[60%] flex items-center mb-2">
                <img src={paymentHistory} alt="Payment history" />
              </div>
              <h6 className="font-roboto font-semibold text-xs text-grey-dark text-center">
                Payment History
              </h6>
              <p className=" text-center text-xs font-roboto text-grey">
                Sign Up and get payment history
              </p>
            </div>
          </div>
          <div className="flex justify-center items-center w-full h-[8vh]">
            <button
              className="bg-blue w-[100%] h-[60%] text-white font-roboto text-[12px]"
              onClick={downloadApp}
            >
              Sign up for better experience
            </button>
          </div>
        </div>
      </div>
    );
  };

  const ShareReceiptEmail = () => {
    return (
      <div
        className="w-screen h-screen flex items-center justify-center absolute z-10 top-0 bottom-0 right-0 left-0 bg-black bg-opacity-60"
        id="my-modal"
      >
        <div className="w-[90%] lg:w-[35%] h-auto bg-white flex flex-col items-center justify-between p-3 shadow-xl rounded transition ease-in-out">
          <div
            className="self-end mb-2"
            onClick={() => setSendEmailModal(false)}
          >
            <img src={close} alt="close" />
          </div>
          {/* <div className="flex items-center justify-between mb-5"></div> */}
          <form
            className="flex items-center flex-col w-full"
            onSubmit={shareReceiptHandler}
          >
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Enter your email id"
              required
              className="w-full my-2 px-3 py-1.5 text-base font-normal text-gray-500 bg-clip-padding border border-solid rounded h-[80%] focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              //       className="form-control w-full my-5 mx-5 px-3 py-1.5 text-base font-normal text-gray-500 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0
              // focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            />
            <button
              className="bg-blue w-[100%] h-8 text-white font-roboto text-[12px] mt-2"
              type="submit"
            >
              Share on Email
            </button>
          </form>
        </div>
      </div>
    );
  };

  return (
    <div className="h-screen w-screen flex flex-col relative">
      {showPopup && <MobilePopup />}
      {sendEmailModal && <ShareReceiptEmail />}
      <header className="flex w-screen h-[8vh] justify-between items-center bg-white lg:bg-[#000000] shadow-md lg:shadow-none">
        <div
          className="ml-5 lg:ml-20 cursor-pointer flex w-20 items-center justify-items-start"
          onClick={() => navigate(-1)}
        >
          <img
            src={desktopBack}
            className="hidden lg:block w-5 h-5 mr-2"
            alt="Back button"
          />
          <img src={mobileBack} className="lg:hidden w-3" alt="Back buton" />
          <p className="hidden lg:block text-white text-md">Back</p>
        </div>
        <div className="cursor-pointer flex justify-center items-center">
          <p className="lg:hidden xl:hidden text-grey font-semibold text-md md:text-2xl font-roboto text-center">
            {/* DETAILS */}
          </p>
        </div>
        <div className="lg:mr-20 hidden lg:flex items-center">
          <div
            className="mr-7 cursor-pointer  lg:flex items-center"
            onClick={shareReceipt}
          >
            <p className="text-white text-md">Email receipt</p>
            <img
              src={emailDesktop}
              className="w-5 h-5 ml-2 "
              alt="Share button"
            />
          </div>
          <div
            className="mr-7 cursor-pointer lg:flex items-center"
            onClick={downloadDesktopReceipt}
          >
            <p className="text-white text-md">Download</p>
            <img
              src={desktopDownoad}
              className="w-5 h-5 ml-2"
              alt="Download button"
            />
          </div>
        </div>
        <div className="mr-3 lg:mr-20 cursor-pointer flex lg:hidden items-center justify-items-end w-20">
          <img
            src={emailMobile}
            className="w-5 h-5 mr-5"
            alt="Download button"
            onClick={shareReceipt}
          />
          <img
            src={mobileDownload}
            className="w-5 h-5"
            alt="Download button"
            onClick={downloadMobileReceipt}
          />
        </div>
      </header>
      <main className="h-[86vh] lg:h-[92vh] w-full  bg-grey lg:bg-grey flex justify-center items-center pt-16 lg:pt-5 overflow-x-hidden overflow-y-auto">
        <article className="bg-white w-[90%] h-auto lg:w-[40%] shadow-lg py-2 px-3 flex flex-col items-center mt-60 md:mt-80">
          <div className="w-full h-full" ref={pdfRef}>
            <section className="flex flex-col justify-center items-center my-3">
              <img src={mfg} className="w-64" alt="MFG logo" />
              <p className="text-grey-dark font-roboto text-[12px] font-semibold">
                Contactless Payment Receipt
              </p>
            </section>
            <hr className="my-2 border-grey-super-light" />
            <section className="flex flex-col my-2">
              <div className="flex justify-between items-center my-1">
                <p className="text-grey-dark font-roboto font-semibold text-[12px] ">
                  Station :
                </p>
                <p className="text-grey font-roboto text-[12px] ">
                  {requiredData.stationName}
                </p>
              </div>
              <div className="flex justify-between items-center my-1">
                <p className="text-grey-dark font-roboto font-semibold text-[12px] ">
                  {requiredData.receiptFor !== VALETING ? "Charger :": "Description of Service :"}
                </p>
                {requiredData.receiptFor !== VALETING ? (
                  <p className="text-grey font-roboto text-[12px] tracking-wider">
                    {requiredData.chargePointId}
                  </p>
                ) : (
                  <p className="text-grey font-roboto text-[12px] ">{requiredData.receiptHeader}</p>
                )}
              </div>
            </section>
            <hr className="my-2 border-grey-super-light" />
            <section className="flex flex-col my-2">
              <div className="flex justify-between items-center my-1">
                <p className="text-grey-dark font-roboto font-semibold text-[12px] ">
                  Card Number :
                </p>
                <p className="text-grey font-roboto text-[12px] ">
                    {requiredData.cardNumberForDetails}
                </p>
              </div>
              <div className="flex justify-between items-center my-1">
                <p className="text-grey-dark font-roboto font-semibold text-[12px] ">
                  Card Type :
                </p>
                <p className="text-grey font-roboto text-[12px] ">
                  {requiredData.cardType}
                </p>
              </div>
              <div className="flex justify-between items-center my-1">
                <p className="text-grey-dark font-roboto font-semibold text-[12px] ">
                  Transaction Type :
                </p>
                <p className="text-grey font-roboto text-[12px] ">
                  {requiredData.transactionType}
                </p>
              </div>
            </section>
            <hr className="my-2 border-grey-super-light" />
            <section className="flex flex-col my-2">
              <div className="flex justify-between items-center my-1">
                <p className="text-grey-dark font-roboto font-semibold text-[12px] ">
                  {requiredData.receiptFor !== VALETING ? SESSION_ID : TRANSACTION_ID}
                </p>
                <p className="text-grey font-roboto text-[12px] ">
                  {requiredData.transactionId}
                </p>
              </div>
              {(requiredData.receiptFor !== VALETING) && (
                <div className="flex justify-between items-center my-1">
                  <p className="text-grey-dark font-roboto font-semibold text-[12px] ">
                    Power Consumed :
                  </p>
                  <p className="text-grey font-roboto text-[12px] ">
                    {requiredData.totalEnergyConsumer} {requiredData.unitForEnergy}
                  </p>
                </div>
              )}
              <div className="flex justify-between items-center my-1">
                <p className="text-grey-dark font-roboto font-semibold text-[12px] ">
                  {(requiredData.receiptFor !== VALETING) ? START_TIME : TRANSACTION_DATE_AND_TIME}
                </p>
                <p className="text-grey font-roboto text-[12px] ">
                  {requiredData.startDateTime}
                </p>
              </div>
              {(requiredData.receiptFor !== VALETING) && (
                <>
                  <div className="flex justify-between items-center my-1">
                    <p className="text-grey-dark font-roboto font-semibold text-[12px] ">
                      End Time :
                    </p>
                    <p className="text-grey font-roboto text-[12px] ">
                      {requiredData.endDateTime}
                    </p>
                  </div>
                  <div className="flex justify-between items-center my-1">
                    <p className="text-grey-dark font-roboto font-semibold text-[12px] ">
                      Duration :
                    </p>
                    <p className="text-grey font-roboto text-[12px] ">
                      {`${requiredData.sessionDuration} ${requiredData.durationUnit}`}
                    </p>
                  </div>
                </>
              )}
              <div className="flex justify-between items-center my-1">
                <p className="text-grey-dark font-roboto font-semibold text-[12px] ">
                  Sub Total (excl. VAT) :
                </p>
                <p className="text-grey font-roboto text-[12px] ">
                  {requiredData.costDetails.amountWithoutTax.toFixed(2)}
                </p>
              </div>
              <div className="flex justify-between items-center my-1">
                <p className="text-grey-dark font-roboto font-semibold text-[12px] ">
                  VAT :
                </p>
                <p className="text-grey font-roboto text-[12px] ">
                  {requiredData.costDetails.taxAmount.toFixed(2)}
                </p>
              </div>
              <div className="flex justify-between items-center my-1">
                <p className="text-grey-dark font-roboto font-semibold text-[12px] ">
                  Total :
                </p>
                <p className="text-grey font-roboto text-[12px] ">
                  {requiredData.costDetails.amountWithTax.toFixed(2)}
                </p>
              </div>
            </section>
            <hr className="border-grey-super-light" />
            <section className="flex flex-col my-1">
              <div className="flex justify-between items-center my-1">
                <p className="text-grey font-roboto text-[11px] ">
                  If you have any queries about your receipt, please contact MFG
                  Customer Services at:{" "}
                  <a
                    href="mailto:CustomerCare@motorfuelgroup.com"
                    className="text-blue tracking-[-0.001em]"
                  >
                    customercare@motorfuelgroup.com
                  </a>{" "}
                  or call us on:{" "}
                  <a href="tel:+44 (0) 1727 898890" className="text-grey-dark">
                    <b> +44 (0) 1727 898890</b>
                  </a>
                </p>
              </div>
              <div className="flex justify-between items-center my-1">
                <p className="text-grey font-roboto text-[11px] ">
                  Regards,<br></br>
                  <span className="font-bold tracking-wide">
                    Motor Fuel Group
                  </span>
                </p>
              </div>
              {/* <div className="flex justify-between items-center my-1">
                <p className="text-grey font-roboto text-[11px] ">
                  ©{new Date().getFullYear() } All Rights Reserved. Motor Fuel Limited - Gladstone
                  Place, 36-38 Upper Marlborough Road, St Albans, Hertfordshire,
                  AL1 3UU
                </p>
              </div>
              <div className="flex justify-between items-center my-1">
                <p className="text-grey font-roboto text-[11px] ">
                  Registered in England & Wales Number: 05206547. VAT Number:
                  123 4141 61
                </p>
              </div> */}
              {/* toLocaleString */}
              <div className="flex justify-between items-center my-1">
                <p className="text-grey font-roboto text-[11px] ">
                  MFG Group of Companies includes
                </p>
              </div>
              <div className="flex justify-between items-center mt-1">
                <p className="text-grey font-roboto text-[11px] ">
                  ©{new Date().getFullYear()} All Rights reserved Motor Fuel
                  Limited - 10 Bricket Road, Marlborough Court,
                </p>
              </div>
              <div className="flex justify-between items-center mb-1">
                <p className="text-grey font-roboto text-[11px] ">
                  St Albans, Hertfordshire, AL1 3JX
                </p>
              </div>
              <div className="flex justify-between items-center mt-1">
                <p className="text-grey font-roboto text-[11px] ">
                  ©{new Date().getFullYear()} All Rights reserved MFG EV Power
                  Limited - 10 Bricket Road, Marlborough Court,
                </p>
              </div>
              <div className="flex justify-between items-center mb-1">
                <p className="text-grey font-roboto text-[11px] ">
                  St Albans, Hertfordshire, AL1 3JX
                </p>
              </div>
              <div className="flex justify-between items-center my-1">
                <p className="text-grey font-roboto text-[11px] ">
                  Registered in England & Wales:
                </p>
              </div>
              <div className="flex justify-between items-center my-1">
                <p className="text-grey font-roboto text-[11px] ">
                  Motor Fuel Limited: 05206547 VAT Number: 123414161
                </p>
              </div>
              <div className="flex justify-between items-center my-1">
                <p className="text-grey font-roboto text-[11px] ">
                  MFG EV Power Limited: 13353242 VAT Number: 123414161
                </p>
              </div>
            </section>
          </div>
        </article>
      </main>
      <footer className="w-full h-[10vh] lg:hidden shadow-md flex justify-center items-center">
        <button
          className="bg-blue w-[90%] h-[60%] text-white font-roboto text-md"
          // onClick={() => setPopup(true)}
          onClick={downloadMobileReceipt}
        >
          Download Receipt
        </button>
      </footer>
    </div>
  );
};

export default Details;
