/**
 *
 * Loader page
 * Created by Om Bhatia on 16/09/2022 14:10 GMT+0530 (India Standard Time)
 * Description - Loader component
 *
 */
const Loader = () => {
  return (
    <main className="fixed inset-0 flex justify-center items-center bg-white z-50">
      <div className="animate-spin inline-block w-20 h-20 border-8 rounded-full border-solid border-[#f3f3f3] border-t-[#89bc28]"></div>
    </main>
  );
};

export default Loader;
