import { Route, Routes } from "react-router-dom";
import HomePage from "../pages/HomePage/HomePage";
import ListPage from "../pages/ListPage/ListPage";
import NotFound from "../pages/NotFoundPage/NotFound";
import Details from "../pages/Details/Details";
import Loader from "../components/Loader/Loader";
import { useLoader } from "./LoaderProvider";
const Router = () => {
  const { loading } = useLoader();
  return (
    <>
      {loading ? <Loader /> : null}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/list" element={<ListPage />} />
        <Route path="/details" element={<Details />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </>
  );
};
export default Router;
