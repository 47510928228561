import logo from "./logo.svg";
import "./App.css";
import Router from "./router/Router";
import { ToastContainer } from "react-toastify";
import { LoaderProvider } from "../src/router/LoaderProvider";
import "react-toastify/dist/ReactToastify.css";
function App() {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <LoaderProvider>
        <Router />
      </LoaderProvider>
    </>
  );
}

export default App;
