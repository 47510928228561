import axios from "axios";

import { BASE_URL } from "../shared/constants/Urls";

export const makeRequest = ({ url, method, data }) => {
  return new Promise((resolve, reject) => {
    try {
      axios({
        method: method,
        url: `${BASE_URL}${url}`,
        withCredentials: false,
        data: data,
        headers: Object.assign(
          {
            Accept: "application/json",
            // Authorization:
            // token && token !== ""
            // ? "Bearer " + JSON.parse(token).token.token
            // : "",
          }
          // headers,
        ),
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
};

export const makeFileRequest = ({ url, method, data }) => {
  return new Promise((resolve, reject) => {
    try {
      axios({
        method: method,
        url: `${BASE_URL}${url}`,
        withCredentials: false,
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
        // headers: Object.assign(
        //   {
        //     ContentType: "multipart/form-data",
        //     // Accept: "application/json",
        //     // Authorization:
        //     // token && token !== ""
        //     // ? "Bearer " + JSON.parse(token).token.token
        //     // : "",
        //   }
        // ),
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
};
