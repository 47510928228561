/**
 *
 * Not found(404) Page
 * Created by Om Bhatia on 16/09/2022 14:10 GMT+0530 (India Standard Time)
 * Description - 404 page for app
 *
 */

import Navbar from "../../components/Navbar/Navbar";
import notFound from "../../shared/assets/images/not-found.png";
import { useNavigate } from "react-router-dom";
const NotFound = () => {
  const navigate = useNavigate();
  return (
    <main className="flex w-screen h-screen flex-col bg-white overflow-hidden">
      <Navbar />
      <section className="h-screen w-full flex flex-col lg:h-[92vh] justify-center items-center">
        <h2 className="text-[#202020E6] font-roboto font-bold text-4xl lg:text-5xl mb-5">
          Error 404!
        </h2>
        <h5 className="text-grey-dark font-roboto text-2xl lg:mb-5 ">
          Something Went Wrong
        </h5>
        <img
          src={notFound}
          alt="Not found"
          className="w-[35%] lg:w-48 my-20 lg:my-5"
        />
        <p className="font-roboto text-base text-[#363636] mt-5 mb-1">Sorry.</p>
        <p className="font-roboto text-base text-[#363636] mb-5">
          We can’t find the page you are looking for.
        </p>
        <button
          className="bg-blue w-[80%] lg:w-[30%] h-10 text-white font-roboto text-md my-5"
          onClick={() => navigate("/")}
        >
          Back to Home
        </button>
      </section>
    </main>
  );
};
export default NotFound;
