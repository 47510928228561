import { useState, useContext, createContext } from "react";

const LoaderContext = createContext();

export const LoaderProvider = ({ children }) => {
  const [loading, setLoader] = useState(false);
  const setLoading = (data) => {
    setLoader(data);
  };
  const value = { loading, setLoading };
  return (
    <LoaderContext.Provider value={value}>{children}</LoaderContext.Provider>
  );
};

export const useLoader = () => {
  return useContext(LoaderContext);
};
