/**
 *
 * Navbar
 * Created by Om Bhatia on 16/09/2022 14:10 GMT+0530 (India Standard Time)
 * Description - Navrbar component
 *
 */
import logo from "../../shared/assets/icons/navbar-mfg-details.svg";
import { useNavigate, useLocation } from "react-router-dom";
const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const homePageRedirection = () => {
    if (location.pathname === "/") {
      window.location.reload();
    } else {
      navigate("/");
    }
  };
  return (
    <header className="hidden lg:flex w-screen h-[8vh] justify-start items-center bg-[#000000]">
      <div className="w-[12rem] ml-20 cursor-pointer" onClick={homePageRedirection}>
        <img src={logo} alt="MFG logo" className="w-full h-full object-cover" />
      </div>
    </header>
  );
};

export default Navbar;
