/**
 *
 * Home Page
 * Created by Om Bhatia on 16/09/2022 14:10 GMT+0530 (India Standard Time)
 * Description - Homepage for collecting user data for session
 *
 */
import { useLayoutEffect, useState, useRef, useEffect } from "react";
import SideImage from "../../components/SideImage/SideImage";
import googleDownload from "../../shared/assets/images/google-play.png";
import appleDownload from "../../shared/assets/images/apple-store.png";
import calender from "../../shared/assets/icons/calender.png";
import close from "../../shared/assets/icons/close.png";
import time from "../../shared/assets/icons/time.png";
import { getStationsList, getUserSessionsList } from "../../api/service";
import { toast } from "react-toastify";
import { useLoader } from "../../router/LoaderProvider";
import { useNavigate } from "react-router-dom";
import io from "socket.io-client";
import {
  NODE_AUTH_TOKEN, NODE_URL,
  PRIVACY_POLICY_FILE_PATH,
  RECAPTCHA_SITE_KEY,
} from "../../shared/constants/Urls";
import { APPLEPAY, GOOGLEWALLET } from "../../shared/constants/appConstant";
import ReCAPTCHA from 'react-google-recaptcha';
const station = require("../HomePage/stations.json");
const HomePage = () => {
  const [stations, setStations] = useState(station);
  const [enableSuggestion, setEnableSuggestion] = useState(false);
  const socketRef = useRef(null);
  const stationDataRef = useRef(station);
  const stationSectionRef = useRef(null);
  const [card, setCard] = useState("");
  const [sessionTimeout, setSessionTimeout] = useState(20000);
  const [amount, setAmount] = useState("");
  const userStation = useRef("");
  const userSelectedStationData = useRef({});
  const dateRef = useRef("");
  const timeRef = useRef("");
  const reCaptchaRef = useRef(null);
  const { setLoading } = useLoader();
  const WORLDLINE = "Worldline";
  const navigate = useNavigate();
  let today = new Date();
  today.setDate(today.getDate());
  let dd = today.getDate();
  let mm = today.getMonth() + 1;
  let yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  today = yyyy + "-" + mm + "-" + dd;
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  

  useEffect(() => {
    socketRef.current = io(NODE_URL, {
      auth: {
        token: NODE_AUTH_TOKEN,
      },
      path: '/chargers-data/',
      withCredentials: true,
      extraHeaders: {
        'mfg-ev-node-backend': 'socket-connection',
      },
    });
    return () => {
      socketRef.current.disconnect()
    }
  }, [])

  useEffect(() => {
    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);
      if (!navigator.onLine) {
        setLoading(false);
        toast.dismiss()
        toast.error("No Internet", { autoClose: 10000 }); 
        console.log('Internet connection lost. Please check your connection.');
      } else {
        setLoading(false);
        localStorage.setItem('toastMessage', 'Internet connection restored.');
        window.location.reload();
      }
    };

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    const storedToastMessage = localStorage.getItem('toastMessage');
    if (storedToastMessage) {
      toast.success(`${storedToastMessage}`, { autoClose: 10000 });
      // Clear the stored toast message from local storage
      localStorage.removeItem('toastMessage');
    }

    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);

  const anonumus = (e) => {
    if (!stationSectionRef.current?.contains(e.target) && enableSuggestion) {
      setEnableSuggestion(false)
      verifyStation()
    }
  }


  useEffect(() => {
    document.addEventListener('mousedown', anonumus)
    return () => {
      document.removeEventListener('mousedown', anonumus)
    }
  }, [enableSuggestion])

  useLayoutEffect(() => {
    setLoading(true);
    //Load station data on page load
    const controller = new AbortController();
    (async () => {
      try {
        let response = await getStationsList(controller.signal);
        if (response?.data && response?.data?.data?.length) {
          setStations(response.data.data);
          StationsSuggestions(response.data.data)
          stationDataRef.current = response.data.data;
          setSessionTimeout(response.data.get_receipts_timeout_value * 1000);
          setLoading(false);
        } else {
          throw new Error(response.message);
        }
      } catch (err) {
        setLoading(false);
        toast.error(`${err.message}`, { autoClose: 10000 });
      }
    })();
    return () => {
      controller.abort();
    };
  }, []);

  // Suggestion modal for stations
  const StationsSuggestions = () => {
    return (
      <div className="relative">
        <div className="absolute w-full h-auto max-h-[38vh] bg-[#f5f5f5] overflow-y-auto z-10">
          {stations.map((st) => {
            return (
              <div
                onClick={() => setUserStationFromList(st.station, st)}
                className="font-roboto text-base px-2 py-1 m-1"
                key={st.id}
              >
                {st.station}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  //Suggestion madal view handler
  const enableSuggestionHandler = () => {
    // setEnableSuggestion((prevState) => !prevState);
    setEnableSuggestion(true);
  };
  function normalizeString(str) {
    return str.toLowerCase().replace(/\s/g, "");
  }
  // Find user entered station in station list
  const findStationHandler = (event) => {
    if (event.target.value) {
      const data = stationDataRef.current.filter((dt) => {
        return normalizeString(dt.station).indexOf(normalizeString(event.target.value)) !== -1;
      });
      setStations(data);
    } else {
      setStations(stationDataRef.current);
    }
  };

  //Set searched user station in input field
  const setUserStationFromList = (data, stationData) => {
    userStation.current.value = data;
    userSelectedStationData.current = stationData;
    setEnableSuggestion((prevState) => !prevState);
    verifyStation();
  };

  // Verify user entered station
  const verifyStation = () => {
    let check = stationDataRef.current.filter((dt) => {
      const normalizedStation = dt.station.toLowerCase().replace(/\s/g, "");
      const normalizedUserStation = userStation.current.value.toLowerCase().replace(/\s/g, "");
      return normalizedStation.indexOf(normalizedUserStation) !== -1;
    });
    if (check.length !== 1) {
      toast.error("Select correct station from list", {
        autoClose: 10000,
        toastId: "selectStaionError",
      });
    } else {
      toast.dismiss("selectStaionError");
    }
  };

  // Set user card details
  const setCardHandler = (event) => {
    if (event.target.value.length !== 4) {
      toast.error("Please enter the correct card details", {
        autoClose: 10000,
        toastId: "cardError",
      });
    } else {
      if (card.indexOf("***") === -1) {
        setCard(`**** **** **** ${event.target.value}`);
      }
    }
  };

  // Set user session amount
  const setAmountHandler = (event) => {
    if (!event.target.value.length) {
      toast.error("Please enter amount", {
        autoClose: 10000,
        toastId: "amountError",
      });
    } else {
      if (amount) {
        setAmount(`${event.target.value}`);
      }
    }
  };

  // Update card handler
  const cardChangeHandler = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setCard(value);
    toast.dismiss("cardError");
  };

  // Update amount handler
  const amountChangeHandler = (e) => {
    const value = e.target.value.replace(/[^0-9.]+|(\..*)\.|^\./g, '$1');
    setAmount(value);
    toast.dismiss("amountError");
  };

  const stationHandler = () => {
    const stationFound = stationDataRef.current.some(dt => dt.station === userStation.current.value);
    return stationFound;
  }

  const contactlessFormHandler = async (event) => {
    const stationConsist = stationHandler()
    event.preventDefault();
    toast.dismiss()
    try {
    if (!stationConsist) {
      setLoading(false);
      toast.error("Please select correct station from list", { autoClose: 10000 });
      return
    }
    if (isOnline) {
    const reCaptchaToken = await reCaptchaRef.current.executeAsync();
    console.log('Token obtained after execution==>', { reCaptchaToken }, '\n');
    setLoading(true);
    const { charge_point_ids, charge_point_names, id, terminal_ids, station_id, station, receipt_hero_site_name, station_payment_terminal } =
      userSelectedStationData.current;
  
    const cardSplit = card.split(" ");
    const cardNumber = cardSplit[3];
    const userDateSplit = dateRef.current.value.split("-");
    const userTimeSplit = timeRef.current.value.split(":");
    const timeCalculate = new Date(
      userDateSplit[0],
      Number(userDateSplit[1]) - 1,
      userDateSplit[2],
      userTimeSplit[0],
      userTimeSplit[1]
    );
    try {
      const dateOfSession = timeCalculate
        .toISOString()
        .split(".")[0]
        .split("T")[0];
      const timeOfSession = timeCalculate
        .toISOString()
        .split(".")[0]
        .split("T")[1]
        .slice(0, -3);
      const handleSessionData = (sessions, sessionDataFromSocket = []) => {
        if (sessionDataFromSocket?.length !== 0) {
          if (sessions?.data && sessions?.data?.sessions) {
            sessions.data.sessions = sessions.data.sessions ? [...sessions.data.sessions, ...sessionDataFromSocket] : sessionDataFromSocket
            navigate("/list", { state: sessions.data.sessions });
          }
          else {
            navigate("/list", { state: sessionDataFromSocket });
          }
        }
        else {
          if (!sessions.status && !sessions.session_not_able_to_find_message) {
            setLoading(false);
            throw new Error(sessions.message);
          }
          if (sessions.status && (sessions.data.sessions && sessions.data.sessions.length)) {
            navigate("/list", { state: sessions.data.sessions });
          } else {
            toast(
              <CustomToastWithLink
                message={
                  sessions.session_not_able_to_find_message ?
                    sessions.session_not_able_to_find_message :
                    sessions.message
                }
                email={sessions.customer_care_email}
                station={station}
                card={cardNumber}
                amount={amount}
                date={dateRef?.current?.value}
                time={timeRef?.current?.value}
              />,
              {
                autoClose: 30000,
                toastId: "userSessionsError",
              }
            );
          }
        }
        setLoading(false);
      };
      const sessions = await getUserSessionsList(
        id,
        station_id,
        cardNumber,
        `${dateOfSession} ${timeOfSession}`,
        charge_point_ids,
        terminal_ids,
        receipt_hero_site_name,
        amount,
        reCaptchaToken,
        charge_point_names
      );
      // Clear the recaptcha token  
      console.log('Deleting token', reCaptchaRef.current.getValue());
      reCaptchaRef.current.reset();
      console.log('Token deleted', reCaptchaRef.current.getValue());
      // console.log(sessions.data);
      if (sessions && sessions?.data?.data && sessions?.data?.data?.request_uuid && station_payment_terminal && station_payment_terminal.includes(WORLDLINE)) {
        setLoading(true)
        const roomID = sessions.data.data.request_uuid;
        console.log('********** roomID *********');
        let dataReceived = false;
        let socketXOnTriggered = false;
        const timer = setTimeout(() => {
          if (!socketXOnTriggered) {
            console.log('Timeout: No data received from socket');
            handleSessionData(sessions.data);
            dataReceived = true;
          }
        }, sessionTimeout);

        socketRef.current.on(roomID, (sessionDataFromSocket) => {
          socketXOnTriggered = true;
          clearTimeout(timer);
          if (!dataReceived) {
            if (
              sessionDataFromSocket.length &&
              sessions?.data?.data?.request_uuid
            ) {
              dataReceived = true;
              handleSessionData(sessions.data, sessionDataFromSocket);
            } else {
              handleSessionData(sessions.data);
            }
          }
        });
      }
      else {
        if (sessions && sessions?.data) {
          handleSessionData(sessions.data);
        } else {
          setLoading(false);
          throw new Error(sessions.message);
        }
      }
    } catch (err) {
      setLoading(false);
      toast.error(`${err.message}`, { autoClose: 10000 });
    }
  }     
   else {
    setLoading(false);
    toast.error("Something went wrong", { autoClose: 10000 }); 
  }     
} catch (error) {
  setLoading(false);
  toast.error("Something went wrong", { autoClose: 10000 });   
}
  };

  // Custom UI for failed session search email
  const CustomToastWithLink = ({
    message,
    email,
    station,
    card,
    date,
    time,
    amount,
  }) => {
    const errorMessage = message.split(email);
    const mail = `mailto:customercare@motorfuelgroup.com?subject=MFG%20Contactless%20Receipt%20Request&body=Dear%20MFG%20Customer%20Care%2C%0A%0AI%20can%20confirm%20the%20details%20below%20and%20request%20a%20contactless%20receipt%20for%20the%20session%0A%0AStation%20Name%20%E2%80%93%20%20${station}%0ACredit%2FDebit%20Card%20Last%204%20Digits%20%E2%80%93%20%20%20${card}%0ADate%20-%20%20${date}%0ATime%20-%20${time}%0AAmount%20-%20${amount}%0A`;
    return (
      <div>
        {errorMessage?.length > 0 ? errorMessage[0] : message}
        <a className="text-blue" href={mail}>
          <u> {email}</u>
        </a>
        {errorMessage?.length > 0 ? errorMessage[1] : ""}
      </div>
    );
  };
  const [showCardModal, setShowCardModal] = useState(false);
  const privacyPolicyHtml = () => {
    window.open(PRIVACY_POLICY_FILE_PATH, "_blank");
  };
  return (
    <main className="flex w-screen h-screen flex-col bg-white overflow-hidden">
      {/* <Navbar /> */}
      {!isOnline && <div className="fixed inset-0 bg-black opacity-50 z-50"></div>}
      <section className="h-screen w-full flex flex-col lg:flex-row lg:h-[100vh]">
        <aside className="h-1/3 w-full lg:block lg:w-1/2 lg:h-full">
          <SideImage />
        </aside>
        <aside className="w-full h-[70%] lg:w-1/2 lg:h-full flex flex-col lg:items-center lg:justify-evenly">
          <section className="w-full h-full flex flex-col p-2 lg:w-[75%] lg:pb-2 overflow-y-auto lg: overflow-hidden justify-center">
            <div className=" w-full">
              <h3 className="leading-4 md:text-3xl text-2xl font-semibold font-roboto my-4 lg:mt-5 lg:mb-6 ">
                Contactless Receipt
              </h3>
              <form
                className="flex flex-col items-stretch h-fit"
                onSubmit={contactlessFormHandler}
              >
                <section className="flex flex-col mb-2 lg:mb-[0.6rem] " ref={stationSectionRef} >
                  <label className="font-roboto text-grey-title text-sm leading-[0.7rem] md:text-medum font-semibold mb-2">
                    Station Name
                  </label>
                  <input
                    type="text"
                    placeholder="e.g. Lyme Green"
                    ref={userStation}
                    defaultValue={userStation.current.value}
                    onFocus={enableSuggestionHandler}
                    onChange={findStationHandler}
                    // onBlur={verifyStation}
                    maxLength={70}
                    required
                    className="w-full h-9 bg-white border-[#d0d0d0] border-solid border px-2 py1"
                  />
                  {enableSuggestion && <StationsSuggestions />}
                </section>
                <section className="flex flex-col mb-2 lg:mb-[0.6rem]">
                  <label className="font-roboto text-grey-title leading-[0.7rem] text-sm md:text-medum font-semibold mb-2 flex justify-between">
                    Credit/Debit Card
                  </label>
                  <input
                    className="w-full h-9 bg-white border-[#d0d0d0] border-solid border px-2 py1"
                    type="text"
                    placeholder="Enter last 4 digits of card"
                    maxLength={4}
                    minLength={4}
                    onBlur={setCardHandler}
                    onChange={cardChangeHandler}
                    value={card}
                    required
                  />
                  <div className="text-grey flex text-xs mt-1">
                    <p>Paid using Apple or Google Pay?
                      <button
                        className="md:pl-1 mr-2 text-grey-title"
                        type="button"
                        onClick={() => setShowCardModal(true)}
                      >
                        <p className="underline text-left">
                          How to find your virtual card number.
                        </p>
                      </button>
                    </p>
                  </div>

                  {showCardModal ? (
                    <>
                      <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div
                          className="fixed inset-0 w-full h-full bg-black opacity-40"
                          onClick={() => setShowCardModal(false)}
                        ></div>
                        <div className="flex items-center min-h-screen px-4 py-8">
                          <div className="relative w-full max-w-2xl p-4 mx-auto bg-white rounded-md shadow-lg">
                            <div className="sm:flex">
                              <div className="ml-2 sm:ml-3 text-left">
                                <div className="flex justify-between">
                                  <h1 className="font-bold text-xl text-grey-red mb-5">
                                    Finding your Virtual Card Number:
                                  </h1>
                                  <div className="cursor-pointer p-2">
                                    <img
                                      alt="close"
                                      src={close}
                                      className="h-[15px] justify-self-center"
                                      onClick={() => setShowCardModal(false)}
                                    />
                                  </div>
                                </div>
                                <div className="mt-2 text-[15px] leading-relaxed text-grey-red">
                                  <h2 className="font-semibold text-lg mb-2">
                                    Google Wallet
                                  </h2>
                                  <ul>
                                    {GOOGLEWALLET.map((line, i) => (
                                      <li key={i + 1} className="flex">
                                        <span>{i + 1}. </span>
                                        <span className="ml-2">{line}</span>
                                      </li>
                                    ))}
                                  </ul>
                                  <h2 className="font-semibold text-lg mt-4 mb-2">
                                    Apple Pay
                                  </h2>
                                  <ul>
                                    {APPLEPAY.map((line, i) => (
                                      <li key={i + 1} className="flex">
                                        <span>{i + 1}. </span>
                                        <span className="ml-2">{line}</span>
                                      </li>
                                    ))}
                                  </ul>
                                  <p className="mt-4">
                                    *Apple Pay assigns different Device Account
                                    Numbers for various devices such as Phone
                                    and Watch.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                </section>
                <section className="flex flex-col mb-2 lg:mb-[0.6rem]">
                  <label className="font-roboto text-grey-title leading-[0.7rem] text-sm md:text-medum font-semibold mb-2">
                    Amount
                  </label>
                  <input
                    className="w-full h-9 bg-white border-[#d0d0d0] border-solid border px-2 py1"
                    type="text"
                    placeholder="Enter the exact transaction value e.g. 23.44"
                    maxLength={6}
                    minLength={1}
                    onBlur={setAmountHandler}
                    onChange={amountChangeHandler}
                    value={amount}
                    required
                  />
                </section>
                <section className="flex flex-col mb-2 lg:mb-[0.6rem]">
                  <label className="font-roboto text-grey-title leading-[0.7rem] text-sm md:text-medum font-semibold mb-2">
                    Choose Date & Time
                  </label>
                  <section className="flex justify-between items-center">
                    <div className="w-[45%] h-9 relative flex items-center justify-between bg-white border-[#d0d0d0] border-solid border px-2 py1">
                      <input
                        type="date"
                        placeholder="dd-mm-yyyy"
                        min="2021-12-31"
                        max={today}
                        // defaultValue={date.toLocaleDateString()}
                        required
                        className="font-roboto border-none outline-0 w-full bg-white"
                        ref={dateRef}
                      />
                      <img src={calender} alt="Calender" className="w-5 h-5" />
                    </div>
                    <div className="w-[45%] h-9 relative flex items-center justify-between bg-white border-[#d0d0d0] border-solid border px-2 py1">
                      <input
                        type="time"
                        required
                        className="font-roboto border-none outline-0 w-full bg-white"
                        // defaultValue={new Date().toLocaleTimeString()}
                        ref={timeRef}
                      />
                      <img src={time} alt="Time" className="w-5 h-5" />
                    </div>
                  </section>
                  <section className="flex flex-col my-7 lg:mb-[0.6rem] lg:hidden">
                    <p className="font-roboto text-md mb-3 lg:mb-[0.1rem] text-center text-xs text-[#575757e5] w-[90%] lg:w-full">
                      Please note: It may take up to 2 hours for your
                      contactless receipt to be available
                    </p>
                  </section>
                </section>
                <section>
                  <ReCAPTCHA
                    sitekey={RECAPTCHA_SITE_KEY}
                    ref={reCaptchaRef}
                    // onChange={() => { console.log(reCaptchaRef.current.getValue()); }}
                    size={'invisible'}
                    type={'image'}
                  />
                </section>
                <section className="flex flex-col items-center justify-center lg:justify-between w-full fixed bottom-4 left-0 right-0 h-12 lg:static lg:shadow-none lg:my-2 lg:h-14 bg-white">
                  <p className="hidden font-roboto text-md mb-3 lg:mb-1 text-center text-xs text-[#575757e5] w-[90%] lg:w-full lg:block">
                    Please note: It may take up to 2 hours for your contactless
                    receipt to be available
                  </p>
                  <button
                    className="h-10 w-[90%] bg-blue text-white font-roboto border-none text-sm lg:w-full lg:h-[60%]"
                    type="submit"
                  >
                    Proceed
                  </button>
                </section>
              </form>
                <footer className="lg:hidden block w-full p-0.5 flex justify-center shadow-lg fixed bottom-0 left-0 right-0 bg-white">
                  <div className="flex">
                    <button
                      className="pl-1 mr-2 text-xs"
                      onClick={privacyPolicyHtml}
                    >
                      Privacy Policy
                    </button>
                  </div>
                </footer>
            </div>
          </section>
          <section className="hidden lg:flex justify-center items-center bg-[#f5f5f5] lg:h-[30%] lg:w-full">
            <div className="w-[40%] h-[80%] flex flex-col justify-center">
              <div className="font-roboto text-lg font-semibold mb-1 text-left text-[#202020e5] w-[80%]">
                Want to experience more features?
              </div>
              <p className="font-roboto text-md mb-3 text-left text-xs text-[#575757e5] w-[80%]">
                Download our Mobile App
              </p>
              <div className="flex items-center justify-between">
                <div
                  className="w-[40%] hover:opacity-40"
                  onClick={() =>
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.mfg.evconnect",
                      "_blank"
                    )
                  }
                >
                  <img
                    src={googleDownload}
                    alt="Android download"
                    className="w-full h-full"
                  />
                </div>
                <div
                  className="w-[40%] hover:opacity-40"
                  onClick={() =>
                    window.open(
                      "https://apps.apple.com/gb/app/mfg-connect/id1579481772",
                      "_blank"
                    )
                  }
                >
                  <img
                    src={appleDownload}
                    alt="IOS download"
                    className="w-full h-full"
                  />
                </div>
              </div>
            </div>
            <div className="w-[40%] h-[90%] self-end bg-desktop-download-form-image bg-no-repeat bg-contain bg-center"></div>
          </section>
        </aside>
      </section>
    </main>
  );
};

export default HomePage;
