export const VALETING = 'Valeting';
export const CHARGING_SESSION = 'Charging Session';
export const RH_CHARGING_SESSION = 'RH Charging Session';
export const SWARCO_CHARGING_SESSION = 'Swarco Charging Session';
export const START_TIME = 'Start Time :';
export const TRANSACTION_DATE_AND_TIME = 'Transaction Date & Time :';
export const SESSION_ID = 'Session Id :';
export const TRANSACTION_ID = 'Transaction ID :';
export const HOURS = 'hours';
export const MINUTES = 'minutes';
export const CONTACTLESS_EMV = 'CONTACTLESS - EMV';
export const EV_POWER = "EV Power"
export const GOOGLEWALLET = [
  "Open the Google Wallet app.",
  "Select the payment card used for the transaction.",
  "Click on the payment card to see its details.",
  "Scroll down to see the last 4 digits of the Virtual account number."
];
export const APPLEPAY = [
  "Open the Settings app.",
  "Scroll and locate Wallet & Apple Pay.",
  "Click on the card used for the transaction to view its details.",
  "Scroll down to see the last 4 digits of Device Account Number or Apple Pay Number."
]
