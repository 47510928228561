import { makeRequest, makeFileRequest } from "./helper";
import { URLS } from "../shared/constants/Urls";
const GET = "GET";
const POST = "POST";
const YES = "Yes";

export const getStationsList = async () => {
  return makeRequest({
    url: URLS.getStations + `?is_contactless_portal=${YES}`,
    method: GET,
    data: undefined,
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const getUserSessionsList = async (
  id,
  stationId,
  card,
  date,
  chargePoints,
  terminals,
  receipt_hero_site_name,
  amount,
  recaptcha_token,
  charge_point_names
) => {
  return makeRequest({
    url: URLS.getUserSessions,
    method: POST,
    data: {
      id: id,
      station_id: stationId,
      card_last_4: card,
      sessions_date: date,
      charge_point_ids: chargePoints,
      terminal_ids: terminals,
      receipt_hero_site_name: receipt_hero_site_name,
      paid_amount: amount,
      recaptcha_token: recaptcha_token,
      charge_point_names: charge_point_names
    },
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const getUserSessionData = async (
  driivzTransactionId,
  payterTransactionId,
  stationName
) => {
  return makeRequest({
    url: URLS.getSessionDetails,
    method: POST,
    data: {
      driivz_transaction_id: driivzTransactionId,
      payter_unique_id: payterTransactionId,
      station_name: stationName,
    },
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const sendUserSessionEmail = async (body) => {
  return makeFileRequest({
    url: URLS.sendSessionEmail,
    method: POST,
    data: body,
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};
