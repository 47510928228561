/**
 *
 * Aside image component
 * Created by Om Bhatia on 16/09/2022 14:10 GMT+0530 (India Standard Time)
 * Description - Aside component to show mfg image
 *
 */
import logo from "../../shared/assets/icons/ev-power-VAT-logo-mobile.svg";
import mobileBack from "../../shared/assets/icons/mobile-back-white.png";
import stationFinder from "../../shared/assets/icons/station-finder.png";
import paymentHistory from "../../shared/assets/icons/payment-history.png";
import { useLocation, useNavigate } from "react-router-dom";
import desktopLogo from "../../shared/assets/icons/ev-power-VAT-logo-desktop.svg";
import { PRIVACY_POLICY_FILE_PATH } from "../../shared/constants/Urls";

const SideImage = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const privacyPolicyHtml = () => {
    window.open(PRIVACY_POLICY_FILE_PATH, "_blank");
  };
  return (
    <>
      <section className="flex items-center justify-center bg-cover bg-center bg-no-repeat w-full bg-mobile-image h-full lg:bg-desktop-image lg:h-[100%] relative">
        {location.pathname === "/list" ? (
          <div
            className="rounded-full w-14 h-14 fixed top-5 left-5 flex justify-center items-center bg-opacity-30 bg-black lg:hidden"
            onClick={() => navigate("/")}
          >
            <img src={mobileBack} alt="mobile back" className="w-[20%]" />
          </div>
        ) : null}
        <figure
          className="lg:hidden md:w-1/4 w-1/3"
          onClick={() =>
            location.pathname === "/" ? window.location.reload() : navigate("/")
          }
        >
          <img src={logo} className="w-full h-full" alt="Logo" />
        </figure>
        <section className="hidden lg:flex absolute top-[15%]">
          <figure
            className="justify-center cursor-pointer"
            onClick={() =>
              location.pathname === "/"
                ? window.location.reload()
                : navigate("/")
            }
          >
            <img
              src={desktopLogo}
              alt="Logo Desktop"
              className="w-full h-full"
            />
          </figure>
        </section>
        <section className="hidden lg:flex lg:w-full lg:h-[28%] lg:justify-center lg:items-center absolute bottom-0 bg-gradient-to-t from-black from-50%">
          <article className="w-1/3 h-5/6 flex justify-center items-center flex-col">
            <figure className="h-12.5 w-12.5">
              <img
                src={stationFinder}
                alt="Station finder"
                className="w-full h-full"
              />
            </figure>
            <h6 className="font-roboto text-white text-sm mt-2 mb-1">
              Station Finder
            </h6>
            <p className="text-center text-grey-light text-sm w-3/4">
              Easy to find nearby charging stations
            </p>
          </article>

          <article className="w-1/3 h-5/6 flex justify-center items-center flex-col">
            <figure className="h-12.5 w-12.5">
              <img
                src={paymentHistory}
                alt="Payment history"
                className="w-full h-full"
              />
            </figure>
            <h6 className="font-roboto text-white text-sm mt-2 mb-1 font-medium">
              Payment History
            </h6>
            <p className="text-center text-grey-light text-sm w-3/4">
              Sign Up and get payment history
            </p>
          </article>
        </section>
        <footer className="absolute bottom-0 w-full p-3 lg:flex justify-center hidden">
          <div className="flex">
            <button
              className="pl-1 mr-2 text-grey-light text-xs"
              onClick={privacyPolicyHtml}
            >
              Privacy Policy
            </button>
          </div>
        </footer>
      </section>
    </>
  );
};

export default SideImage;
