/**
 *
 * List data
 * Created by Om Bhatia on 16/09/2022 14:10 GMT+0530 (India Standard Time)
 * Description - List data component
 *
 */
import thunder from "../../shared/assets/icons/thunder.png";
import pond from "../../shared/assets/icons/pond.png";
import time from "../../shared/assets/icons/hourglass.png";
import { toast } from "react-toastify";
import { CHARGING_SESSION, RH_CHARGING_SESSION, VALETING, SWARCO_CHARGING_SESSION, EV_POWER, HOURS, MINUTES, CONTACTLESS_EMV } from "../../shared/constants/appConstant";
import { useState, useEffect } from "react";
const getCostDetails = (totalAmount, appliedTaxRate, receiptFor) => {
  let costDetails = [];
  costDetails.amountWithTax = Number([VALETING, CHARGING_SESSION].includes(receiptFor) ? totalAmount / 100 : totalAmount);
  costDetails.amountWithoutTax =
    Math.floor((costDetails.amountWithTax / Number(100 + appliedTaxRate)) * 100 * 100) / 100;
  costDetails.taxAmount =
    Math.floor(((costDetails.amountWithoutTax * appliedTaxRate) / 100) * 100) / 100;
  if (costDetails.taxAmount / costDetails.amountWithoutTax > appliedTaxRate / 100) {
    costDetails.taxAmount = costDetails.taxAmount - 0.01;
    costDetails.amountWithoutTax = costDetails.amountWithoutTax + 0.01;
  }
  if (costDetails.amountWithoutTax + costDetails.taxAmount !== costDetails.amountWithTax) {
    costDetails.amountWithoutTax +=
      costDetails.amountWithTax - (costDetails.amountWithoutTax + costDetails.taxAmount);
    costDetails.amountWithoutTax = Number(costDetails.amountWithoutTax.toFixed(2));
  }
  return costDetails
}
const dateTimeAndDuration = (data, receiptFor) => {
  let formatStartDate = receiptFor === SWARCO_CHARGING_SESSION ? data.swarco_data["Start Date"] : data.driivz_data.startOn;
  let formatEndDate = receiptFor === SWARCO_CHARGING_SESSION  ? data.swarco_data["End Date"] : data.driivz_data.stopOn;
  formatStartDate = `${formatStartDate}`.replace("T", " ").replace("-", "/").replace("-", "/").split(".")[0];
  let startDate = new Date(formatStartDate)
  formatEndDate = `${formatEndDate}`.replace("T", " ").replace("-", "/").replace("-", "/").split(".")[0];
  let endDate = new Date(formatEndDate)
  let diff = endDate.getTime() - startDate.getTime();
  let msec = diff;
  let hh = Math.floor(msec / 1000 / 60 / 60);
  msec -= hh * 1000 * 60 * 60;
  let mm = Math.floor(msec / 1000 / 60);
  msec -= mm * 1000 * 60;
  let ss = Math.floor(msec / 1000);
  msec -= ss * 1000;
  let hrs = hh < 10 ? `0${hh}` : hh;
  let mins = mm < 10 ? `0${mm}` : mm;
  let sec = ss < 10 ? `0${ss}` : ss;
  return [
    hh ? `${hrs}:${mins}` : `${mins}:${sec}`,
    hh ? HOURS : MINUTES,
    `${startDate.toLocaleString("en-GB").split(", ")[0]} ${startDate.toLocaleString("en-GB").split(", ")[1].slice(0, -3)}`,
    `${endDate.toLocaleString("en-GB").split(", ")[0]} ${endDate.toLocaleString("en-GB").split(", ")[1].slice(0, -3)}`,
    `${startDate.toLocaleString("en-GB").split(", ")[0]}`
  ]
}

const ListDetail = ({ showSessionDetails, data }) => {
  const [requiredData, setRequiredData] = useState(null);
  let sessionDuration, durationUnit, formattedStartDate, formattedEndDate, dateForFileName;
      const getReceiptData = receiptData => {
        switch (receiptData) {
            case VALETING:
            let valetingTransactionTime = new Date(`${data.payter_data["@timestamp"]}`.replace("T", " ").replace("-", "/").split(".")[0])
            return {
              receiptFor: VALETING,
              receiptHeader: VALETING,
              stationName: data.station_name,
              stationId: data.station_id,
              startDateTime: valetingTransactionTime.toLocaleString("en-GB").replace(",", "").slice(0, -3),
              cardBrand: data?.payter_data?.brandName,
              cardLastFourDigitsForList: `***********${data.payter_data["extra-TXN-MASKED-PAN"].slice(-4)}`,
              costDetails: getCostDetails(data.payter_data.committedAmount, 20, data.receipt_for),
              cardNumberForDetails: data.payter_data["extra-TXN-MASKED-PAN"],
              cardType: data.payter_data["brandName"],
              transactionType: `${data.payter_data.ifd} - ${data.payter_data.paymentType}`,
              transactionId: data.payter_data.transactionId,
              dateForFileName: valetingTransactionTime.toLocaleString("en-GB").split(", ")[0],
            };
            case CHARGING_SESSION:
            [sessionDuration, durationUnit, formattedStartDate, formattedEndDate, dateForFileName] = dateTimeAndDuration(data, data.receipt_for);  
            return {
              receiptFor: CHARGING_SESSION,
              receiptHeader: EV_POWER,
              stationName: data.driivz_data.station.address.address1.split(",")[0],
              stationId: data.station_id,
              startDateTime: formattedStartDate,
              endDateTime: formattedEndDate,
              cardBrand: data?.payter_data?.brandName,
              cardLastFourDigitsForList: `***********${data.payter_data["extra-TXN-MASKED-PAN"].slice(-4)}`,
              costDetails: getCostDetails(Number(data.payter_data.committedAmount), Number(data.driivz_data.cost.totalTaxRate), data.receipt_for),
              cardNumberForDetails: data.payter_data["extra-TXN-MASKED-PAN"],
              cardType: data.payter_data["brandName"],
              transactionType: `${data.payter_data.ifd} - ${data.payter_data.paymentType}`,
              transactionId: data.driivz_data.transactionId,
              sessionDuration: sessionDuration,
              durationUnit: durationUnit,
              totalEnergyConsumer: data.driivz_data.totalEnergy,
              unitForEnergy: 'kWh',
              chargePointId: data.driivz_data.station.caption,
              dateForFileName: dateForFileName,
            };
            case RH_CHARGING_SESSION:
            [sessionDuration, durationUnit, formattedStartDate, formattedEndDate, dateForFileName] = dateTimeAndDuration(data, data.receipt_for);
            return {
              receiptFor: RH_CHARGING_SESSION,
              receiptHeader: EV_POWER,
              stationName: data.driivz_data.station.address.address1.split(",")[0],
              stationId: data.station_id,
              startDateTime: formattedStartDate,
              endDateTime: formattedEndDate,
              cardBrand: data?.RH_data?.maskedPan,
              cardLastFourDigitsForList: `***********${data.RH_data["maskedPan"].slice(-4)}`,
              costDetails: getCostDetails(Number(data.RH_data.amount), Number(data.driivz_data.cost.totalTaxRate), data.receipt_for),
              cardNumberForDetails: data.RH_data["maskedPan"],
              cardType: data.RH_data["cardBrand"],
              transactionType: CONTACTLESS_EMV,
              transactionId: data.driivz_data.transactionId,
              sessionDuration: sessionDuration,
              durationUnit: durationUnit,
              totalEnergyConsumer: data.driivz_data.totalEnergy,
              unitForEnergy: 'kWh',
              chargePointId: data.driivz_data.station.caption,
              dateForFileName: dateForFileName,
            };
            case SWARCO_CHARGING_SESSION:
            [sessionDuration, durationUnit, formattedStartDate, formattedEndDate, dateForFileName] = dateTimeAndDuration(data, data.receipt_for);
            return {
              receiptFor: SWARCO_CHARGING_SESSION,
              receiptHeader: EV_POWER,
              stationName: data.station_name,
              stationId: data.station_id,
              startDateTime: formattedStartDate,
              endDateTime: formattedEndDate,
              cardBrand: data?.advam_data?.Type,
              cardLastFourDigitsForList: `***********${data.advam_data.Card.slice(-4)}`,
              costDetails: getCostDetails(Number(data.swarco_data.Cost.total), Number(data.swarco_data.Cost.totalTaxRate), data.receipt_for),
              cardNumberForDetails: data.advam_data.Card,
              cardType: data.advam_data.Type,
              transactionType: CONTACTLESS_EMV,
              transactionId: data.swarco_data["ID"],
              sessionDuration: sessionDuration,
              durationUnit: durationUnit,
              totalEnergyConsumer: data.swarco_data["Total kWh"].toFixed(2),
              unitForEnergy: 'kWh',
              chargePointId: data.swarco_data.Charger.split(",")[0].trim(),
              dateForFileName: dateForFileName,
            };
        }
        };
  useEffect(() => {
      setRequiredData(getReceiptData(data.receipt_for))
  }, [data]);

  return (
    <div
      className="bg-white w-full h-auto shadow-lg my-2 lg:my-3 xl:my-3 py-2 px-3 lg:py-3 xl:py-3 lg:px-5 xl:px-5 hover:opacity-60"
      onClick={() => showSessionDetails(requiredData, data)}
    >
      { requiredData ?
      <><section className="flex justify-between items-center my-1">
          <h4 className="text-base text-black lg:text-lg font-semibold font-roboto">{requiredData.receiptHeader}</h4>
        </section><hr className="my-2 border-grey-light" /><section className="flex justify-between items-center my-1">
            <p className="font-roboto text-grey-dark text-sm md:text-medium lg:text-medium xl:text-medium font-semibold ">
              {requiredData.stationName}
            </p>
          </section><section className="font-roboto flex justify-between items-center mb-1">
            <p className="font-roboto text-grey text-xs lg:text-sm xl:text-sm ">
              {requiredData.stationId}
            </p>
            <p className="font-roboto text-grey text-xs lg:text-sm xl:text-sm ">
              {requiredData.startDateTime}
            </p>
          </section><section className="flex justify-between items-center">
            <p className="font-roboto text-grey text-xs lg:text-sm xl:text-sm ">
              {requiredData.cardBrand}
            </p>
            <p className="font-roboto text-grey text-xs lg:text-sm xl:text-sm ">
              {requiredData.cardLastFourDigitsForList}
            </p>
          </section><hr className="my-2 border-grey-light" /><section className="flex justify-between items-center my-1">
            {requiredData.receiptFor !== VALETING && <div className="flex items-center">
              <img src={thunder} className="w-2.5" />
              <p className="font-roboto text-grey-dark pt-0.5 text-sm md:text-medium lg:text-[1rem] xl:text-medium text-center font-semibold mx-1">
                {requiredData.totalEnergyConsumer}
              </p>
              <p className="font-roboto text-grey text-xs text-center pt-1">{requiredData.unitForEnergy}</p>
            </div>}
            <div className="flex items-center">
              <img src={pond} className="w-2.5" />
              <p className="font-roboto text-grey-dark pt-0.5 text-sm md:text-medium lg:text-[1rem] xl:text-medium text-center font-semibold mx-1">
                {requiredData.costDetails.amountWithTax.toFixed(2)}
              </p>
            </div>
            {requiredData.receiptFor !== VALETING && <div className="flex items-center">
              <img src={time} className="w-2.5" />
              <p className="font-roboto text-grey-dark pt-0.5 text-sm lg:text-[1rem] xl:text-medium text-center font-semibold mx-1">
                {requiredData.sessionDuration}
              </p>
              <p className="font-roboto text-grey text-center text-xs align-bottom pt-1">
                {requiredData.durationUnit}
              </p>
            </div>}
          </section></>
      : null}
    </div >
  );
};

export default ListDetail;
