export const BASE_URL = "https://ev.mymfg.co.uk";
export const URLS = {
  getStations: "/contactless/api/get-station-list/",
  getUserSessions: "/contactless/api/v4/get-user-sessions-receipt-list/",
  sendSessionEmail: "/contactless/api/send-session-data-email/",
};
export const PRIVACY_POLICY_FILE_PATH =
  "https://mfgevlivestorage.blob.core.windows.net/media" +
  "/privacyPolicy.html";
export const NODE_URL = "https://ev-charging-api.mymfg.co.uk/";
export const NODE_AUTH_TOKEN =
  "e67f87b9565c3f6e6d9c1c75ebb70894d0d535017a7a1206ea78006d0c4046e7d254531868989941803699286cfbcd11e1c1fb0cf8d3d62c06d4c25113381701";
// Replace the site with required key
export const RECAPTCHA_SITE_KEY = "6LenC88pAAAAADXKRX7CHjyCva9A1uNXzxOoYUdh";
